<template>
  <create backRoute="SuperAdminClinic" />
</template>

<script>
import create from "../../components/community-portal/create.vue";

export default {
  components: {
    create,
  },
  data: () => ({}),
};
</script>
